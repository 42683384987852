export const PLOT_RESOLUTION = 10
export const PLOT_ZOOM_THRESHOLD = 12.5

export const HIGHLIGHT_ZOOM = 14

export const FETCH_RESOLUTION = 2
export const FETCH_ZOOM_THRESHOLD = 3

export const SMALL_CLUSTER_RESOLUTION = 7
export const SMALL_CLUSTER_ZOOM_THRESHOLD = 9.5

export const MEDIUM_CLUSTER_RESOLUTION = 5
export const MEDIUM_CLUSTER_ZOOM_THRESHOLD = 6

export const LARGE_CLUSTER_RESOLUTION = 3
export const LARGE_CLUSTER_ZOOM_THRESHOLD = 3

export const LARGEST_CLUSTER_RESOLUTION = 2
export const LARGEST_CLUSTER_ZOOM_THRESHOLD = 0

export const TWO_MINUTES_MS = 120000

export const RESERVED_ROUTES = [
	'account',
	'basket',
	'checkout',
	'index',
	'info',
	'login',
	'plot',
	'privacy',
	'profile',
	'terms',
	'plot-image',
	'profile-image',
	'images',
	'magic-link',
]

export const PLOT_COLORS = {
	ownedFill: '#9333ea', // purple 600
	ownedLine: '#c084fc', // purple 400
	highlightedFill: '#d97706', // amber 600
	highlightedLine: '#fbbf24', // amber 400
	selectedFill: '#475569', /// slate 600
	selectedLine: '#0f172a', // slate 900
}
